<template>
    <div ref="wrapper" class="flex flex-wrap">
        <div class="item_card mr-3">
            <div class="item_card__header py-2 flex justify-between items-center item-title">
                <div class="card_label">Плюсы. Что помогло?</div>
                <a-button 
                    type="link" 
                    flaticon 
                    size="small"
                    v-tippy="{ inertia : true, duration : '[600,300]'}"
                    content="Добавить"
                    shape="circle"
                    icon="fi-rr-add"
                    @click="addHandler('pluses')" />
            </div>
            <div class="item_card__wrapper">
                <a-spin :spinning="listLoading.pluses" class="w-full" size="small">
                    <div class="item_scroll_wrap">
                        <div v-if="empty.pluses" class="flex justify-center">
                            <a-button type="primary" ghost class="mt-3 mb-1 px-8" @click="addHandler('pluses')">
                                Добавить
                            </a-button>
                        </div>
                        <div v-for="item in list['pluses']" :key="item.id" class="r_card">
                            <div class="r_card__header">
                                <Profiler 
                                    :avatarSize="22"
                                    nameClass="text-sm"
                                    hideSupportTag
                                    :user="item.author" />
                                <div v-if="item.author && user && user.id === item.author.id" class="flex items-center pl-2">
                                    <a-button 
                                        type="ui" 
                                        size="small" 
                                        ghost
                                        v-tippy="{ inertia : true, duration : '[600,300]'}"
                                        content="Редактировать"
                                        flaticon
                                        shape="circle"
                                        icon="fi-rr-edit"
                                        @click="editHandler(item)" />
                                    <a-dropdown :getPopupContainer="getPopupContainer">
                                        <a-button 
                                            type="ui" 
                                            size="small" 
                                            ghost
                                            flaticon
                                            class="ml-1"
                                            shape="circle"
                                            icon="fi-rr-menu-dots-vertical" />
                                        <a-menu slot="overlay">
                                            <a-menu-item class="text_red" @click="deleteItem(item)">
                                                Удалить
                                            </a-menu-item>
                                        </a-menu>
                                    </a-dropdown>
                                </div>
                            </div>
                            <div class="r_card__body">
                                <p class="card_text">{{ expandedItems[item.id] ? item.content : truncateText(item.content, maxLength) }}</p>
                                <a-button 
                                    v-if="item.content.length > maxLength" 
                                    type="link"
                                    size="small" 
                                    class="mt-1 px-0 py-0"
                                    @click="toggleExpand(item.id)">
                                    {{ expandedItems[item.id] ? 'Скрыть' : 'Подробнее' }}
                                </a-button>
                                <div class="date mt-2">{{ $moment(item.created_at).format('DD.MM.YYYY HH:mm') }}</div>
                            </div>
                        </div>
                    </div>
                </a-spin>
            </div>
        </div>
        <div class="item_card mr-3">
            <div class="item_card__header py-2 flex justify-between items-center item-title">
                <div class="card_label">Минусы. Что мешало?</div>
                <a-button 
                    type="link" 
                    flaticon 
                    size="small"
                    v-tippy="{ inertia : true, duration : '[600,300]'}"
                    content="Добавить"
                    shape="circle"
                    icon="fi-rr-add"
                    @click="addHandler('minuses')" />
            </div>
            <div class="item_card__wrapper">
                <a-spin :spinning="listLoading.minuses" class="w-full" size="small">
                    <div class="item_scroll_wrap">
                        <div v-if="empty.minuses" class="flex justify-center">
                            <a-button type="primary" ghost class="mt-3 mb-1 px-8" @click="addHandler('minuses')">
                                Добавить
                            </a-button>
                        </div>
                        <div v-for="item in list['minuses']" :key="item.id" class="r_card">
                            <div class="r_card__header">
                                <Profiler 
                                    :avatarSize="22"
                                    nameClass="text-sm"
                                    hideSupportTag
                                    :user="item.author" />
                                <div v-if="item.author && user && user.id === item.author.id" class="flex items-center pl-2">
                                    <a-button 
                                        type="ui" 
                                        size="small" 
                                        ghost
                                        v-tippy="{ inertia : true, duration : '[600,300]'}"
                                        content="Редактировать"
                                        flaticon
                                        shape="circle"
                                        icon="fi-rr-edit"
                                        @click="editHandler(item)" />
                                    <a-dropdown :getPopupContainer="getPopupContainer">
                                        <a-button 
                                            type="ui" 
                                            size="small" 
                                            ghost
                                            flaticon
                                            class="ml-1"
                                            shape="circle"
                                            icon="fi-rr-menu-dots-vertical" />
                                        <a-menu slot="overlay">
                                            <a-menu-item class="text_red" @click="deleteItem(item)">
                                                Удалить
                                            </a-menu-item>
                                        </a-menu>
                                    </a-dropdown>
                                </div>
                            </div>
                            <div class="r_card__body">
                                <p class="card_text">{{ expandedItems[item.id] ? item.content : truncateText(item.content, maxLength) }}</p>
                                <a-button 
                                    v-if="item.content.length > maxLength" 
                                    type="link"
                                    size="small" 
                                    class="mt-1 px-0 py-0"
                                    @click="toggleExpand(item.id)">
                                    {{ expandedItems[item.id] ? 'Скрыть' : 'Подробнее' }}
                                </a-button>
                                <div class="date mt-2">{{ $moment(item.created_at).format('DD.MM.YYYY HH:mm') }}</div>
                            </div>
                        </div>
                    </div>
                </a-spin>
            </div>
        </div>
        <div class="item_card mr-2">
            <div class="item_card__header py-2 flex justify-between items-center item-title">
                <div class="card_label">Идеи. Как улучшить работу?</div>
                <a-button 
                    type="link" 
                    flaticon 
                    size="small"
                    v-tippy="{ inertia : true, duration : '[600,300]'}"
                    content="Добавить"
                    shape="circle"
                    icon="fi-rr-add"
                    @click="addHandler('ideas')" />
            </div>
            <div class="item_card__wrapper">
                <a-spin :spinning="listLoading.ideas" class="w-full" size="small">
                    <div class="item_scroll_wrap">
                        <div v-if="empty.ideas" class="flex justify-center">
                            <a-button type="primary" ghost class="mt-3 mb-1 px-8" @click="addHandler('ideas')">
                                Добавить
                            </a-button>
                        </div>
                        <div v-for="item in list['ideas']" :key="item.id" class="r_card">
                            <div class="r_card__header">
                                <Profiler 
                                    :avatarSize="22"
                                    nameClass="text-sm"
                                    hideSupportTag
                                    :user="item.author" />
                                <div v-if="item.author && user && user.id === item.author.id" class="flex items-center pl-2">
                                    <a-button 
                                        type="ui" 
                                        size="small" 
                                        ghost
                                        v-tippy="{ inertia : true, duration : '[600,300]'}"
                                        content="Редактировать"
                                        flaticon
                                        shape="circle"
                                        icon="fi-rr-edit"
                                        @click="editHandler(item)" />
                                    <a-dropdown :getPopupContainer="getPopupContainer">
                                        <a-button 
                                            type="ui" 
                                            size="small" 
                                            ghost
                                            flaticon
                                            class="ml-1"
                                            shape="circle"
                                            icon="fi-rr-menu-dots-vertical" />
                                        <a-menu slot="overlay">
                                            <a-menu-item class="text_red" @click="deleteItem(item)">
                                                Удалить
                                            </a-menu-item>
                                        </a-menu>
                                    </a-dropdown>
                                </div>
                            </div>
                            <div class="r_card__body">
                                <p class="card_text">{{ expandedItems[item.id] ? item.content : truncateText(item.content, maxLength) }}</p>
                                <a-button 
                                    v-if="item.content.length > maxLength" 
                                    type="link"
                                    size="small" 
                                    class="mt-1 px-0 py-0"
                                    @click="toggleExpand(item.id)">
                                    {{ expandedItems[item.id] ? 'Скрыть' : 'Подробнее' }}
                                </a-button>
                                <div class="date mt-2">{{ $moment(item.created_at).format('DD.MM.YYYY HH:mm') }}</div>
                            </div>
                        </div>
                    </div>
                </a-spin>
            </div>
        </div>
        <a-modal
            :title="edit ? 'Редактировать ретроспективу' : 'Добавить ретроспективу'"
            :footer="false"
            :getContainer="getPopupContainer"
            :afterClose="afterClose"
            :visible="visible"
            @cancel="visible = false">
            <a-form-model
                ref="ruleForm"
                :model="form"
                :rules="rules">
                <a-form-model-item ref="content" prop="content">
                    <template v-if="!edit" #label>
                        <template v-if="form.retrospective_type === 'ideas'">
                            Идеи. Как улучшить работу?
                        </template>
                        <template v-if="form.retrospective_type === 'minuses'">
                            Минусы. Что мешало?
                        </template>
                        <template v-if="form.retrospective_type === 'pluses'">
                            Плюсы. Что помогло?
                        </template>
                    </template>
                    <a-textarea
                        v-model="form.content"
                        placeholder="Введите текст"
                        :auto-size="{ minRows: 4, maxRows: 10 }"
                        @change="handleBodyChange" />
                    <div class="body_length flex justify-end" style="opacity: 0.6;">
                        {{form.content.length}}/{{ bodyMaxCount }}
                    </div>
                </a-form-model-item>
                <a-button type="primary" :loading="loading" size="large" block @click="formSubmit()">
                    Сохранить
                </a-button>
            </a-form-model>
        </a-modal>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    computed: {
        ...mapState({
            user: state => state.user.user,
        })
    },
    props: {
        sprint: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            bodyMaxCount: 1000,
            maxLength: 250,
            visible: false,
            loading: false,
            expandedItems: {},
            list: {
                pluses: [],
                ideas: [],
                minuses: []
            },
            empty: {
                pluses: false,
                ideas: false,
                minuses: false
            },
            listLoading: {
                pluses: false,
                ideas: false,
                minuses: false
            },
            edit: false,
            form: {
                content: "",
                retrospective_type: "pluses",
                sprint: null
            },
            rules: {
                content: [
                    { required: true, message: 'Обязательно для заполнения', trigger: 'blur' }
                ]
            }
        }
    },
    created() {
        this.listLoad()
    },
    methods: {
        handleBodyChange(event) {
            const value = event.target.value.slice(0, this.bodyMaxCount)
            this.$set(this.form, 'content', value)
        },
        toggleExpand(id) {
            this.$set(this.expandedItems, id, !this.expandedItems[id]);
        },
        truncateText(text, length) {
            return text.length > length ? text.substring(0, length) + '...' : text;
        },
        listLoad() {
            this.getRetrospective('pluses')
            this.getRetrospective('ideas')
            this.getRetrospective('minuses')
        },
        afterClose() {
            this.edit = false
            this.form = {
                content: "",
                retrospective_type: "pluses",
                sprint: null
            }
        },
        getPopupContainer() {
            return this.$refs.wrapper
        },
        addHandler(type) {
            this.form.retrospective_type = type
            this.visible = true
        },
        deleteItem(item) {
            this.$confirm({
                title: 'Вы действительно хотите удалить ретроспективу',
                content: '',
                okText: 'Удалить',
                okType: 'danger',
                zIndex: 2000,
                getContainer: this.getPopupContainer,
                closable: true,
                maskClosable: true,
                cancelText: 'Закрыть',
                onOk: () => {
                    return new Promise((resolve, reject) => {
                        this.$http.post('/table_actions/update_is_active/', {
                            id: item.id,
                            is_active: false
                        })
                            .then(() => {
                                this.$message.success('Ретроспектива успешно удалена')
                                this.listLoad()
                                resolve()
                            })
                            .catch(e => {
                                console.log(e)
                                reject(e)
                            })
                    })
                }
            })
        },
        async getRetrospective(type) {
            try {
                this.listLoading[type] = true
                const { data } = await this.$http.get('/retrospective/', {
                    params: {
                        page_size: 100,
                        retrospective_type: type,
                        sprint: this.sprint.id
                    }
                })
                if(data) {
                    if(data.results.length) {
                        this.empty[type] = false
                    } else {
                        this.empty[type] = true
                    }
                    this.list[type] = data.results
                }
            } catch(e) {
                console.log(e)
            } finally {
                this.listLoading[type] = false
            }
        },
        editHandler(item) {
            this.edit = true
            this.form = {...item}
            this.visible = true
        },
        formSubmit() {
            this.$refs.ruleForm.validate(async valid => {
                if (valid) {
                    const queryData = {...this.form}
                    queryData.sprint = this.sprint.id
                    if(this.edit) {
                        try {
                            this.loading = true
                            const { data } = await this.$http.put(`/retrospective/${queryData.id}/`, queryData)
                            if(data) {
                                this.visible = false
                                this.listLoad()
                                this.$message.success('Ретроспектива успешно обновлена')
                            }
                        } catch(e) {
                            console.log(e)
                        } finally {
                            this.loading = false
                        }
                    } else {
                        try {
                            this.loading = true
                            const { data } = await this.$http.post('/retrospective/', queryData)
                            if(data) {
                                this.visible = false
                                this.listLoad()
                                this.$message.success('Ретроспектива успешно создана')
                            }
                        } catch(e) {
                            console.log(e)
                        } finally {
                            this.loading = false
                        }
                    }
                } else {
                    return false
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.r_card{
    box-shadow: 0 1px 0 rgba(9, 30, 66, .15);
    border: 0;
    border-radius: var(--borderRadius);
    background: #fff;
    padding: 10px;
    color: #000;
    &:not(:last-child){
        margin-bottom: 10px;
    }
    &__header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
    }
    .card_text{
        opacity: 0.6;
        word-break: break-word;
    }
    .date{
        opacity: 0.6;
    }
}
.item_card{
    width: 100%;
    height: 100%;
    scroll-snap-align: start;
    flex-grow: 0;
    background-color: #eff2f5;
    border-radius: var(--borderRadius);
    flex-shrink: 0;
    padding-bottom: 5px;
    overflow: hidden;
    @media (min-width: 768px) {
        max-width: 290px;
    }
    @media (min-width: 1400px) {
        max-width: 340px;
    }
    &:not(:last-child){
        margin-bottom: 15px;
    }
    &__header{
        padding-left: 10px;
        padding-right: 10px;
        color: #000;
    }
    &__wrapper{
        height: calc(100% - 40px);
        .item_scroll_wrap{
            padding-left: 7px;
            padding-right: 7px;
            height: 100%;
            overflow-y: auto;
            overflow-x: hidden;
            padding-block: 5px;
        }
    }
}
</style>